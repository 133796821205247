
import { computed, defineComponent, useContext, useStore } from '@nuxtjs/composition-api'
import { COOKIE_CONSENT_APPROVED, COOKIE_CONSENT_REJECTED } from '~/store/piwik'
import { setupTranslationPrefixer } from '~/assets/translation'
import ContentBlocked from '~/components/Organism/Cookie/ContentBlocked.vue'
import { hasPiwik } from '~/plugins/piwik'

export default defineComponent({
  components: { ContentBlocked },
  props: {
    type: { type: String, required: true },
    alternativeUrl: { type: String, required: false, default: null },
  },
  setup(props) {
    const store = useStore()
    const { $config } = useContext()
    const compliance = computed(() => store.getters['piwik/compliance'](props.type))
    const cookiesRejected = computed(() => hasPiwik($config) && compliance.value === COOKIE_CONSENT_REJECTED)
    const cookiesApproved = computed(() => !hasPiwik($config) || compliance.value === COOKIE_CONSENT_APPROVED)
    return {
      compliance,
      cookiesRejected,
      cookiesApproved,
      ...setupTranslationPrefixer('cookies'),
    }
  },
})
