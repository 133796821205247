
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    url: { type: String, required: true },
  },
  setup(props) {
    const checkPlatformVideoUrl = (url) => {
      const match = url.match('(vimeo|youtu)')
      return match ? match[0] : null
    }

    const getReformattedVideoUrl = (url, embed = true) => {
      if (checkPlatformVideoUrl(url) === 'youtu') {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = url.match(regExp)
        return match && match[2].length ? (!embed ? 'https:' : '') + '//www.youtube.com/' + (embed ? 'embed/' : 'watch?v=') + match[2] : undefined
      } else if (checkPlatformVideoUrl(url) === 'vimeo') {
        const match = /vimeo.*\/(\d+)/i.exec(url)
        return match && match[1] ? 'https://' + (embed ? 'player.vimeo.com/video/' : 'vimeo.com/') + match[1] : undefined
      }
    }

    return {
      videoUrl: computed(() => getReformattedVideoUrl(props.url)),
      alternativeUrl: computed(() => getReformattedVideoUrl(props.url, false)),
      platform: computed(() => checkPlatformVideoUrl(props.url)),
    }
  },
})
