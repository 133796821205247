
import { defineComponent } from '@nuxtjs/composition-api'
import { openPiwikConsentForm } from '~/plugins/piwik'
import { setupTranslationPrefixer } from '~/assets/translation'

export default defineComponent({
  props: {
    text: { type: String, required: true },
    alternativeUrl: { type: String, required: false, default: null },
  },
  setup() {
    return {
      openPiwikConsentForm,
      ...setupTranslationPrefixer('cookies'),
    }
  },
})
